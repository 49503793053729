import React from 'react';
import PropTypes from 'prop-types';

import './AnimatedButton.scss';

const AnimatedButton = (props) => {
  const { icon, label, onClick } = props;
  return (
    <div className="animatedButton" onClick={onClick}>
      <div className={`ic icon-${icon}`} />
      <div className="label">{label}</div>
    </div>
  );
};

AnimatedButton.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
};

AnimatedButton.defaultProps = {
  icon: '',
  label: '',
  onClick: undefined,
};

export default AnimatedButton;
