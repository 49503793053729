import React from 'react';

const SearchField = ({ onSearch, placeholder }) => (
  <div className="search-field">
    <input
      placeholder={placeholder}
      onKeyUp={(event) => {
        event.persist();
        if (onSearch) {
          onSearch(event);
        }
      }}
    />
    <div className="circle-search-btn">
      <i className="ic icon-search" />
    </div>
  </div>
);

export default SearchField;
