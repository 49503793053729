import React, { Component } from 'react';
import leftArrow from '../../assets/left-arrow.svg';
import clocksImage from '../../assets/trial-ended-img.png';
import monaImage from '../../assets/block-interactions-img.png';
import invitesImage from '../../assets/trial-ended-invites.svg';
import aristoImage from '../../assets/block-analytics-img.png';
import venusImage from '../../assets/block-custom-engine-img.svg';
import leadGenImage from '../../assets/trial-ended-cards-leadgen.svg';
import UpgradePlanButton from '../../UpgradePlanButton/UpgradePlanButton';
import ContactUsButton from '../ContactUsButton/ContactUsButton';
import { getSidQueryString } from '../../utils/helpers';
import eventTypes, { trackEvent } from '../../utils/events';

const getRelevantImage = (imageName) => {
  switch (imageName) {
    case 'block-interactions-img':
      return monaImage;
    case 'trial-ended-invites':
      return invitesImage;
    case 'trial-ended-cards-leadgen':
      return leadGenImage;
    case 'trial-ended-img':
      return clocksImage;
    case 'block-analytics-img':
      return aristoImage;
    case 'block-custom-engine-img':
      return venusImage;
    default:
      return '';
  }
};

const renderBullets = (list, isContactUs) => {
  if (list && list.bullets) {
    return (
      <ul className="restriction-popup__content__list">
        {list.bullets.map((bullet, index) => (
          <li
            key={`${list.color + index}`}
            className={`restriction-popup__content__list__bullet ${isContactUs ? 'contact-us' : ''} ${list.color}`}
          >
            <span>{bullet}</span>
          </li>
        ))}
      </ul>
    );
  }
  return null;
};

const HUBSPOT_DEFAULT_FORM_STYLE = { width: '600px', height: '580px', padding: '24px 40px' };
class RestrictionPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showContactForm: false,
    };
  }

  componentDidMount() {
    const { publisher, popupObj: { origin } } = this.props;
    trackEvent(eventTypes.UPGRADE_POPUP_LOADED, publisher, origin, {
      type: 'page_loaded',
      element: 'action',
      category: 'top_of_funnel',
    });
  }

  redirectToDashboard = () => {
    const { editorUrl, publisher } = this.props;
    trackEvent(eventTypes.UPGRADE_POPUP_CLICKED_BACK_TO_DASHBOARD, publisher, origin);
    window.location.replace(`${editorUrl}${getSidQueryString(true)}`);
  };

  renderBackButton = (withClose) => {
    if (!withClose) {
      return (
        <div className="restriction-popup__content__back" onClick={this.redirectToDashboard}>
          <img
            alt="left-arrow"
            className="restriction-popup__content__back__arrow-icon"
            src={leftArrow}
          />
          <span className="restriction-popup__content__back__text">Back to dashboard</span>
        </div>
      );
    }
    return null;
  };

  showContactUsForm = (show) => {
    this.setState({ showContactForm: show });
  };

  render() {
    const {
      onClose, appName, user, publisher, redirectTo, popupObj, paymentsUrl, wixIntegrationUrl, upgradeRedirectUrl,
    } = this.props;
    const {
      isContactUs, withClose, img, title, infoText, body, list, upgradeBtnPosition, origin,
    } = popupObj;

    const onPaymentClose = withClose ? onClose : () => {};
    const { height, bottom, right } = img;
    const { showContactForm } = this.state;
    const hubspotFormStyle = { ...HUBSPOT_DEFAULT_FORM_STYLE, display: showContactForm ? '' : 'none' };

    return (
      <div className="restriction-popup-container header-modal-overlay">
        <div className={`modal ${appName === 'editorUnit' ? 'editor-modal' : ''}`}>
          { withClose && (
          <i
            className="ic icon-close-popup close"
            onClick={() => {
              trackEvent(eventTypes.UPGRADE_POPUP_CLICKED_CLOSE, publisher, origin);
              onClose();
            }}
          />
          )}
          <div style={hubspotFormStyle} id="hubspotForm" />
          { !showContactForm
          && (
          <div className={`restriction-popup__content ${isContactUs ? 'contact-us' : ''}`}>
            <div className={`restriction-popup__content__wrapper ${isContactUs ? 'contact-us' : ''}`}>
              <div className={`restriction-popup__content__title ${isContactUs ? 'contact-us' : ''}`}>{title}</div>
              <div className={`restriction-popup__content__secondary-title ${isContactUs ? 'contact-us' : ''}`}>
                <div>
                  {infoText}
                </div>
              </div>
              <div className="restriction-popup__content__text">
                {body}
                {renderBullets(list, isContactUs)}
              </div>
              { isContactUs
                ? <ContactUsButton showContactUsForm={this.showContactUsForm} />
                : this.renderBackButton(withClose)}
            </div>
            <div className={`${img.background}-background`}>
              <img
                className="restriction-popup__content__image"
                alt=""
                style={{ height, bottom, right }}
                src={getRelevantImage(img.name)}
              />
            </div>
          </div>
          )}
        </div>
        {!isContactUs
        && (
        <UpgradePlanButton
          classNames="restriction-popup-modal restriction-popup__content__upgrade-button"
          position={upgradeBtnPosition}
          origin={origin}
          user={user}
          onClose={onPaymentClose}
          publisher={publisher}
          redirectTo={redirectTo}
          paymentsUrl={paymentsUrl}
          wixIntegrationUrl={wixIntegrationUrl}
          upgradeRedirectUrl={upgradeRedirectUrl}
        />
        )}
        {showContactForm
        && (
        <button
          type="button"
          className="contact-back-button"
          onClick={(() => this.showContactUsForm(false))}
        >
          <i className="ic icon-arrow-back-left" />
        </button>
        )}
      </div>
    );
  }
}

export default RestrictionPopup;
