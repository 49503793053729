import React from 'react';
import UpgradePlanButton from '../UpgradePlanButton/UpgradePlanButton';
import './UpgradePlanMenuComponent.scss';

const UpgradePlanMenuComponent = ({
  user, paymentsUrl, wixIntegrationUrl, publisher, upgradeRedirectUrl,
}) => (
  <section className="upgrade-plan-menu-section">
    <p>
      Choose the right plan for you and start creating
      <br />
      better engagements with your customers
    </p>
    <UpgradePlanButton
      origin="createDropdown"
      classNames="upgrade-plan-menu-btn"
      user={user}
      publisher={publisher}
      paymentsUrl={paymentsUrl}
      wixIntegrationUrl={wixIntegrationUrl}
      upgradeRedirectUrl={upgradeRedirectUrl}
    />
    <div className="upgrade-plan-menu-img" />
  </section>
);

export default UpgradePlanMenuComponent;
