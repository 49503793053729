import React from 'react';
import AutoPoll from '../assets/auto-poll.svg';

import './CreateEngineButton.scss';

const NewFeatureNotification = ({ action, trackNewFeature }) => {
  const onClick = () => {
    trackNewFeature();
    action('autoPoll');
  };

  return (
    <div id="new-feature-notification">
      <div className="description">
        <div>
          <h2 className="description-title">NEW ENGINE</h2>
          <p className="description-paragraph">
            Try Auto Poll, our new smart engine that can generate polls automatically.
            Just add tags with the poll subject and your poll
            (4 answers per question with 4 slides) will be ready to use!
          </p>
        </div>
        <img className="auto-poll-image" src={AutoPoll} alt="auto poll" />
      </div>
      <button type="button" className="try-button" onClick={onClick}>
        <i className="ic icon-auto-poll" />
        Try automatic poll
      </button>
    </div>
  );
};

export default NewFeatureNotification;
