import staticFlags from '../../../feature-flags.json';

const FLAGR_URL = 'https://flags.apester.com';

const { Flagr } = require('@apester/flags/browser');

let flags;

export function initFlagr(user) {
  if (flags) {
    return;
  }
  const extraParams = {
    userId: user.userId,
    roles: user.roles,
    publisher: user.publishers.map((publisher) => publisher.publisherId),
  };
  flags = new Flagr({ flagrUrl: FLAGR_URL, staticFlags, extraParams });
}

export async function getFeature(feature) {
  try {
    const flag = await flags.get(feature, { entityContext: {} });
    return flag && flag.variantKey === 'on' ? flag : undefined;
  } catch (e) {
    return undefined;
  }
}
