import React, { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { debounce } from 'lodash';

import SearchField from '../../SearchField/SearchField';

const FETCHING_CHUNK = 20;
const DEBOUNCE_TIMEOUT = 1000;
const MIN_CHARS_FOR_SEARCH = 2;

const scrollingAdminPopupContent = () => {
  const searchElement = document.getElementsByClassName('popupHeader')[0];
  if (searchElement) {
    const scroll = document.getElementsByClassName('popupBody')[0];
    if (scroll && scroll.scrollTop >= 10) {
      searchElement.classList.add('scrolled');
    } else {
      searchElement.classList.remove('scrolled');
    }
  }
};

class AdminPopup extends Component {
  itemSearch;

  constructor(props) {
    super(props);
    this.state = {
      isAdminChannelView: true,
      items: [],
      hasMore: true,
      isFetching: false,
    };
    this.debouncedFilter = debounce(this.debounceFunc, DEBOUNCE_TIMEOUT);
  }

  componentDidUpdate(prevProps, prevState) {
    const { isAdminChannelView } = this.state;
    if (prevState.isAdminChannelView !== isAdminChannelView) {
      this.fetchItems(this.itemSearch)(1);
    }
  }

  debounceFunc = (event) => {
    const searchInput = event.target;
    const text = (searchInput && searchInput.value) || '';
    if (!text.length || text.length >= MIN_CHARS_FOR_SEARCH) {
      this.fetchItems(text)(1);
    }
  };

  getChannels = async (from, limit, search) => {
    const { usersUrl } = this.props;
    const channels = await fetch(`${usersUrl}/publisher/slim?from=${from}&limit=${limit}&searchName=${search}`, {
      credentials: 'include',
    });
    const channelsObj = await channels.json();
    return channelsObj.payload;
  };

  getGroups = async (from, limit, search) => {
    const { usersUrl } = this.props;
    const url = `${usersUrl}/groups?noPopulate=true&slim=true&from=${from}&limit=${limit}&searchName=${search}`;
    const groups = await fetch(url, {
      credentials: 'include',
    });

    const groupsObj = await groups.json();
    return groupsObj.payload;
  };

  fetchItems = (search) => {
    const { isAdminChannelView, items } = this.state;
    this.itemSearch = search;
    const fetchFunc = isAdminChannelView ? this.getChannels : this.getGroups;
    return async (page) => {
      const from = (page - 1) * FETCHING_CHUNK;
      const encodedSearch = search ? encodeURIComponent(search) : '';
      this.setState({
        isFetching: true,
      });
      const newItems = await fetchFunc(from || 0, FETCHING_CHUNK || 100, encodedSearch);

      this.setState({
        items: from ? items.concat(newItems) : newItems,
        hasMore: newItems.length === FETCHING_CHUNK,
        isFetching: false,
      });
    };
  };

  handleChangeAdminView = (isAdminChannel) => {
    this.setState({
      isAdminChannelView: isAdminChannel,
    });
  };

  manageChannel = (channel) => {
    const { toggleChannelAndGroupPopup, openMenuLink } = this.props;
    toggleChannelAndGroupPopup(false);
    openMenuLink('console', 'manageChannel', channel.publisherId);
  };

  manageGroup = (group) => {
    const { toggleChannelAndGroupPopup, openMenuLink } = this.props;
    toggleChannelAndGroupPopup(false);
    openMenuLink('console', 'manageGroup', group._id);
  };

  render() {
    const { toggleChannelAndGroupPopup } = this.props;
    const {
      isAdminChannelView, hasMore, items, isFetching,
    } = this.state;
    const searchPlaceholder = isAdminChannelView
      ? 'Search for a channel'
      : 'Search for a group';
    return (
      <>
        <div
          className="adminPopupOverlay"
          onClick={() => {
            toggleChannelAndGroupPopup(false);
          }}
        />
        <div className="adminPopup">
          <div className="popupHeader">
            <div className="toggleButton">
              <div
                className={`toggleOption ${isAdminChannelView ? 'active' : ''}`}
                onClick={() => this.handleChangeAdminView(true)}
              >
                channels
              </div>
              <div
                className={`toggleOption ${
                  !isAdminChannelView ? 'active' : ''
                }`}
                onClick={() => this.handleChangeAdminView(false)}
              >
                groups
              </div>
            </div>
            <SearchField
              placeholder={searchPlaceholder}
              onSearch={(param) => this.debouncedFilter(param)}
            />
          </div>
          <div
            className="popupBody"
            ref={(ref) => { (this.scrollParentRef = ref); }}
            onScroll={scrollingAdminPopupContent}
          >
            <InfiniteScroll
              threshold={40}
              loadMore={this.fetchItems(this.itemSearch)}
              hasMore={hasMore && !isFetching}
              useWindow={false}
              getScrollParent={() => this.scrollParentRef}
              loader={(
                <div className="loader" key={0}>
                  Loading ...
                </div>
)}
            >
              {items.map((item) => (
                <div
                  key={`popup-content-${isAdminChannelView ? item.publisherId : item.groupId}`}
                  className="dropdown-button large"
                  onClick={() => (isAdminChannelView
                    ? this.manageChannel(item)
                    : this.manageGroup(item))}
                >
                  <div className="innerText">{item.name}</div>
                </div>
              ))}
            </InfiniteScroll>
          </div>
        </div>
      </>
    );
  }
}

export default AdminPopup;
