import React, { Component } from 'react';
import ACGLoader from '../assets/acg_loader.svg';
import ACGImgCompleted from '../assets/acg_process_completed.svg';
import EventsNames from '../utils/events';
import { getACGJob, isWixUser, getSidQueryString } from '../utils/helpers';

const BUTTON_MARGIN_DASHBOARD = 11;
const BUTTON_MARGIN_NOT_DASHBOARD = 13;
const TRACKING_ACG_INTERVAL_TIME = 4000;

const JOB_STATUS = {
  FAILED: 'failed',
  SUCCESS: 'success',
  PENDING: 'pending',
};

const renderACGInProcess = () => (
  <>
    <img alt="automatic content generator" className="acg-loading-indicator" src={ACGLoader} />
    <div className="default-label acg-in-process">
      Apester is working on creating your new content, we’ll let you know when all done!
    </div>
  </>
);

class ACGNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIdsProcess: undefined,
    };
  }

  async componentDidMount() {
    const { activeJobs } = this.props;
    const activeIdsProcess = activeJobs.map((job) => job.id);
    this.setState({ activeIdsProcess }, this.trackingACGProcess());
  }

  redirectProcessCompleted = () => {
    const {
      isUserInDashboard, closeACGNotification, publisherId, editorUrl, wixIntegrationUrl,
    } = this.props;

    if (isWixUser()) {
      window.location.href = `${wixIntegrationUrl}/wix-editor?channelId=${publisherId}${getSidQueryString()}`;
      return;
    }
    if (isUserInDashboard) {
      closeACGNotification();
    } else {
      window.location.replace(`${editorUrl}${getSidQueryString(true)}`);
    }
  };

  getButtonText = () => {
    const { isUserInDashboard } = this.props;
    if (isWixUser()) {
      return 'edit';
    }
    if (isUserInDashboard) {
      return 'got it';
    }
    return 'go to dashboard';
  };

  renderACGCompleted = () => {
    const { isUserInDashboard } = this.props;
    const buttonLeftMargin = isUserInDashboard ? BUTTON_MARGIN_DASHBOARD : BUTTON_MARGIN_NOT_DASHBOARD;
    return (
      <>
        <img alt="automatic content generator" className="acg-completed-icon" src={ACGImgCompleted} />
        <div className="acg-completed-label">
          <span className="default-label acg-done-label">
            All Done!!!
          </span>
          <span className="default-label">
            {`Your content is ready for your review
                ${isUserInDashboard ? ' in dashboard' : ''}`}
          </span>
          <button
            type="button"
            style={{ marginLeft: `${buttonLeftMargin}px` }}
            onClick={this.redirectProcessCompleted}
            className="acg-primary-button"
          >
            {this.getButtonText()}
          </button>
        </div>
      </>
    );
  };

  handleCompletedJob = (intervalId, jobId, jobData) => {
    clearInterval(intervalId);
    const { activeIdsProcess } = this.state;
    const { trackEvent } = this.props;
    const { status } = jobData || {};
    const idsStillInProcess = activeIdsProcess.filter((id) => id !== jobId);
    this.setState({ activeIdsProcess: idsStillInProcess });
    if (status === JOB_STATUS.SUCCESS) {
      const { successNumOfSteps } = jobData || {};
      trackEvent(EventsNames.ACG_PROCESS_COMPLETED, { successNumOfSteps });
    } else if (status === JOB_STATUS.FAILED) {
      trackEvent(EventsNames.ACG_STORY_FAILED);
    }
  };

  jobStatusInterval = async (intervalId, job) => {
    const { jobsUrl } = this.props;
    try {
      const jobData = await getACGJob(job.id, jobsUrl);
      const { status: jobStatus } = jobData || {};
      if (jobStatus !== JOB_STATUS.PENDING) {
        this.handleCompletedJob(intervalId, job.id, jobData);
      }
    } catch (e) {
      this.handleCompletedJob(intervalId, job.id);
    }
  };

  trackingACGProcess = () => {
    const { activeJobs } = this.props;
    activeJobs.forEach((job) => {
      const jobIntervalId = setInterval(() => this.jobStatusInterval(jobIntervalId, job), TRACKING_ACG_INTERVAL_TIME);
    });
  };

  render() {
    const { activeIdsProcess } = this.state;
    const isProcessCompleted = activeIdsProcess && activeIdsProcess.length === 0;

    return (
      <div id="notification" className="acg">
        {isProcessCompleted ? (
          this.renderACGCompleted()
        ) : (
          renderACGInProcess()
        )}
      </div>
    );
  }
}

export default ACGNotification;
