import React, { Component, useEffect } from 'react';

import engineList from './consts';
import AnimatedButton from './AnimatedButton/AnimatedButton';
import NewFeatureNotification from './NewFeatureNotification';
import UpgradePlanMenuComponent from '../UpgradePlanMenuComponent/UpgradePlanMenuComponent';
import EVENT_TYPES, { trackEvent } from '../utils/events';

import './CreateEngineButton.scss';

class CreateEngineButton extends Component {
  constructor(props) {
    super(props);

    this.buttonRef = React.createRef();
    this.dropdownRef = React.createRef();
    this.closingTimeout = undefined;
    this.state = {
      isOpen: false,
      showOverlay: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.isOpen !== state.isOpen) {
      return {
        isOpen: props.isOpen,
      };
    }
    return null;
  }

  getAllowedEngineByChannels = async (channelId) => {
    const { authUrl } = this.props;
    const params = { channelId: channelId };
    const allowedEngine = await fetch(`${authUrl}/channels/use-engines`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });
    const allowedEngineObj = await allowedEngine.json();
    return allowedEngineObj.payload;
  };

  handleClearTimeout() {
    clearTimeout(this.closingTimeout);
    this.closingTimeout = undefined;
    const dropDownElement = this.dropdownRef.current;
    if (dropDownElement) {
      dropDownElement.classList.remove('slideOut');
      dropDownElement.classList.add('slideIn');
      this.setState({
        showOverlay: true,
      });
    } else {
      this.setState({
        isOpen: true,
        showOverlay: true,
      });
    }
  }

  handleCloseMenu() {
    const dropDownElement = this.dropdownRef.current;
    dropDownElement.classList.add('slideOut');
    dropDownElement.classList.remove('slideIn');
    this.setState({
      showOverlay: false,
    });
    this.closingTimeout = setTimeout(() => {
      this.closingTimeout = undefined;
      this.setState({
        isOpen: false,
      });
    }, 1000);
  }

  trackNewFeature() {
    const { publisher } = this.props;
    trackEvent(EVENT_TYPES.TRY_AUTO_POLL, publisher);
  }

  toggleMenu() {
    const { isOpen } = this.state;
    const { trackButtonClicked, onToggleMenu, user, publisher  } = this.props;
    if (this.closingTimeout) {
      this.handleClearTimeout();
      return;
    }
    if (isOpen) {
      onToggleMenu(false);
      this.handleCloseMenu();
    } else {
      const publisherId = publisher?.publisherId || user.defaultPublisher;
      this.getAllowedEngineByChannels(publisherId).then(channelEngines => {
        this.setState({ channelEngines: channelEngines.allowedEngines }); 
      }).finally(() => {
          trackButtonClicked('createButton');
          onToggleMenu(true);
          this.setState({
            isOpen: true,
            showOverlay: true,
          });
        }
      );
    }
  }

  calcPosition() {
    const { sectionRef } = this.props;
    return { top: sectionRef.current.clientHeight, right: -30 };
  }

  renderOverlay() {
    const { showOverlay } = this.state;
    return showOverlay ? (
      <div className="overlay" onClick={this.toggleMenu.bind(this)} />
    ) : null;
  }

  // eslint-disable-next-line class-methods-use-this
  renderMenuButtons(openMenuLink) {
    const { channelEngines } = this.state;

    const engineListAllowed = channelEngines
      ? engineList.map((element) => {
          const item = {...element};
          const engineElements = element.engines.filter((el) =>
            channelEngines.includes(el.name)
          );
          item.engines = engineElements;
          return item;
        })
      : engineList;

    return engineListAllowed.map((item) => (
      <div key={item.description}>
        <div className="sectionTitle">{item.description}</div>
        <div className="sectionColumn">
          {item.engines.map((engine) => (
            <AnimatedButton
              key={engine.label}
              label={engine.label}
              icon={engine.icon}
              onClick={() => {
                if (engine.action === 'autoPoll') {
                  this.trackNewFeature();
                }
                openMenuLink(engine.action);
              }}
            />
          ))}
        </div>
      </div>
    ));
  }

  renderMenu() {
    const { isOpen } = this.state;
    const {
      openMenuLink,
      hasTrialNotification,
      user,
      paymentsUrl,
      wixIntegrationUrl,
      publisher,
      upgradeRedirectUrl,
    } = this.props;
    if (isOpen) {
      const dropDownPosition = this.calcPosition();

      return (
        <>
          {this.renderOverlay()}
          <div
            className="dropDownContainer"
            style={
              dropDownPosition
                ? { top: dropDownPosition.top, right: dropDownPosition.right }
                : ''
            }
          >
            <div ref={this.dropdownRef} className="dropDownMenu slideIn">
              <div className="createButtonContent">
                {this.renderMenuButtons(openMenuLink)}
              </div>
              {hasTrialNotification ? (
                <UpgradePlanMenuComponent
                  user={user}
                  paymentsUrl={paymentsUrl}
                  wixIntegrationUrl={wixIntegrationUrl}
                  publisher={publisher}
                  upgradeRedirectUrl={upgradeRedirectUrl}
                />
              ) : (
                <NewFeatureNotification
                  action={openMenuLink}
                  trackNewFeature={() => this.trackNewFeature()}
                />
              )}
            </div>
          </div>
        </>
      );
    }
    return null;
  }

  render() {
    return (
      <>
        <button
          type="button"
          ref={this.buttonRef}
          className="create-button"
          onClick={this.toggleMenu.bind(this)}
        >
          Create
        </button>
        {this.renderMenu()}
      </>
    );
  }
}

export default CreateEngineButton;
