module.exports = {
  adsetPublicUrl: 'https://adset.stg.apester.dev',
  analyticsBaseUrl: 'https://analytics.stg.apester.dev',
  authBaseUrl: 'https://auth.stg.apester.dev',
  campaignBaseUrl: 'https://campaign.stg.apester.dev',
  campaignApiPublicUrl: 'https://campaign-api.stg.apester.dev',
  adsetApiPublicUrl: 'https://adset-api.stg.apester.dev',
  consoleBaseUrl: 'https://console.stg.apester.dev',
  vreminiUrl: 'https://vremini.stg.apester.dev',
  editorBaseUrl: 'https://app.stg.apester.dev',
  eventsPublicUrl: 'https://events.stg.apester.dev',
  imagesNewUrl: 'https://img.stg.apester.dev',
  imagesUrl: 'https://img.stg.apester.dev',
  interactionBaseUrl: 'https://interaction.stg.apester.dev',
  jobsUrl: 'https://jobs-api.stg.apester.dev',
  paymentsUrl: 'https://payments.stg.apester.dev/',
  planApiUrl: 'https://plans-api.stg.apester.dev',
  playerBaseUrl: 'https://renderer.stg.apester.dev/v2/',
  portalUrl: 'https://portal.stg.apester.dev',
  staticBucket: 'https://static.stg.apester.dev/',
  stripePK: 'pk_test_ws6qGQGNuKq9GtmPTbtiUQ4g00k49LBFFY',
  usersBaseUrl: 'https://users.stg.apester.dev',
  wixIntegrationUrl: 'https://wix-integration.stg.apester.dev',
  webhooksBaseUrl: 'https://webhooks.stg.apester.dev',
  release: '8273ee14354d017a2967e3531e3b39596ba9d171',
};
