import React from 'react';
import popupImage from '../../assets/no-channel-popup-image.svg';

const NoChannelPopup = (props) => {
  const { onClose, createNewChannel, appName } = props;
  // eslint-disable-next-line max-len
  const linkUrl = 'https://help.apester.com/hc/en-us/articles/360000734429-What-s-the-use-of-a-Channel-and-why-should-I-create-one';

  return (
    <div className="no-channel-popup-container header-modal-overlay">
      <div className={`modal ${appName === 'editorUnit' ? 'editor-modal' : ''}`}>
        <i className="ic icon-close-popup close" onClick={onClose} />
        <div className="no-channel-popup__content">
          <div className="no-channel-popup__content__box">
            <span className="no-channel-popup__content__title">To publish this unit, you&#39;ll need to</span>
            <p className="no-channel-popup__content__text">CREATE A CHANNEL</p>
            <img alt="" className="no-channel-popup__content__image" src={popupImage} />
            <p className="no-channel-popup__content__text2">Don&#39;t worry, all your changes will be saved.</p>
            <div onClick={createNewChannel}>
              <div className="no-channel-popup__content__create-new-channel-btn">
                CREATE NEW CHANNEL
              </div>
              <a
                href={linkUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="no-channel-popup__content__learn-more-btn"
              >
                Learn more about channels
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoChannelPopup;
