import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';

import SearchField from '../SearchField/SearchField';
import ChannelItem from '../ChannelItem/ChannelItem';
import { getSubscriptionClassObject } from '../utils/helpers';

import EVENT_TYPES, { trackEvent } from '../utils/events';

const PAGE_SIZE = 10;

const scrollingChannels = () => {
  const searchElement = document.getElementsByClassName(
    'inner-menu-title',
  )[0];
  if (searchElement) {
    const scroll = document.getElementsByClassName('inner-menu-body')[0];
    if (scroll && scroll.scrollTop >= 10) {
      searchElement.classList.add('scrolled');
    } else {
      searchElement.classList.remove('scrolled');
    }
  }
};

const calcDropdownHeight = (items) => items.length * 50 + 20;

class MenuButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChannelListOpen: false,
      scrolledChannels: [],
      hasMore: true,
    };
  }

  componentDidUpdate(prevProps) {
    const { filteredChannels } = this.props;
    if (prevProps.filteredChannels !== filteredChannels) {
      this.handleShowMore(1);
    }
  }

  mainPic = () => {
    const {
      imagesUrl,
      profilePicUrl,
      selectedChannel,
      isChannelView,
    } = this.props;
    if (isChannelView && selectedChannel) {
      return (
        <img
          alt=""
          className="ic"
          src={`${imagesUrl}/insecure/fit/0/36/ce/0/plain/${encodeURIComponent(
            selectedChannel.imagePath
              || (selectedChannel.profileImage
                && selectedChannel.profileImage.path),
          )}`}
        />
      );
    }
    if (!profilePicUrl) {
      return <div className="ic icon-user-profile" />;
    }
    return <img alt="" className="ic" src={profilePicUrl} />;
  };

  profilePic = () => {
    const { profilePicUrl } = this.props;

    if (profilePicUrl === '') {
      return <div className="ic icon-user-profile" />;
    }
    return <img alt="" className="ic" src={profilePicUrl} />;
  };

  selectChannel = (item) => {
    const { handleSelectChannel } = this.props;
    this.setState({
      isChannelListOpen: false,
    });
    handleSelectChannel(item);
  };

  onRegularButtonClick = ({ appName, menuOption }) => {
    const { openMenuLink, selectedChannel } = this.props;

    trackEvent(EVENT_TYPES.DROPDOWN_CLICKED, selectedChannel, menuOption);

    if (appName === 'portal') {
      const searchParams = new URLSearchParams(window.location.search);
      const origin = searchParams.get('origin');

      trackEvent(EVENT_TYPES.TEMPLATE_PAGE_CLICKED, selectedChannel, origin, {
        type: 'element',
        action: 'redirect',
        element: 'button',
        category: 'top_of_funnel',
        channelVertical: menuOption,
      });
    }
    openMenuLink(appName, menuOption);
  };

  mainButton = () => {
    const {
      isChannelView, selectedChannel, type, text, subscriptions, onMouseOver, dropdownList, button,
    } = this.props;

    const badgeClass = selectedChannel
      ? getSubscriptionClassObject(subscriptions, selectedChannel.publisherId).type
      : '';
    const showBadge = selectedChannel && isChannelView && badgeClass;

    return (
      <>
        <div
          onMouseOver={onMouseOver}
          onFocus={() => {}}
          onClick={() => type === 'regular' && !dropdownList && this.onRegularButtonClick(button)}
          className={`main-button ${showBadge ? `plans-${badgeClass}` : ''}`}
        >
          {type === 'regular' ? (
            <div className={`textContainer text ${dropdownList ? 'has-dropdown' : ''}`}>{text}</div>
          ) : (
            <>{this.mainPic()}</>
          )}
        </div>
        { showBadge
          && (
            <div className={`badge badge__${badgeClass}`}>
              <i className="ic icon-star" />
            </div>
          )}
      </>
    );
  };

  renderSearchField = () => {
    const { hasSearch, filterChannels } = this.props;
    if (hasSearch) {
      return (
        <div className="dropdown-button no-hover">
          <SearchField
            placeholder="Search channel"
            onSearch={filterChannels}
          />
        </div>
      );
    }
    return null;
  };

  handleClickEditProfile = () => {
    const { openMenuLink, selectedChannel } = this.props;
    trackEvent(EVENT_TYPES.DROPDOWN_CLICKED, selectedChannel, 'editProfile');
    openMenuLink('console', 'editProfile');
  };

  handleShowMore = (page) => {
    const { filteredChannels } = this.props;
    this.setState({
      scrolledChannels: filteredChannels.slice(0, page * PAGE_SIZE),
      hasMore: page * PAGE_SIZE < filteredChannels.length - 1,
    });
  };

  renderChannels = () => {
    const { hasMore, scrolledChannels, isChannelListOpen } = this.state;
    const {
      subscriptions, filteredChannels, imagesUrl, selectedChannel, handleCreateChannel,
    } = this.props;
    return isChannelListOpen ? (
      <div className="menuInnerItems">
        <div className="inner-menu-title">
          <div
            className="dropdown-button"
            onClick={() => {
              trackEvent(EVENT_TYPES.DROPDOWN_CLICKED, selectedChannel, 'switchBack');
              this.setState({ isChannelListOpen: false });
            }}
          >
            <i className="ic icon-arrow-left" />
            <span className="innerText">channels</span>
          </div>
          {this.renderSearchField()}
        </div>
        <div
          className="inner-menu-body"
          onScroll={scrollingChannels}
          ref={(ref) => { (this.scrollParentRef = ref); }}
        >
          {filteredChannels ? (
            <InfiniteScroll
              initialLoad
              threshold={40}
              pageStart={0}
              loadMore={this.handleShowMore}
              hasMore={hasMore}
              useWindow={false}
              getScrollParent={() => this.scrollParentRef}
              loader={
                (
                  <div className="loader" key={0}>
                    Loading ...
                  </div>
                )
              }
            >
              {scrolledChannels.map((scrolledChannel, index) => {
                const subscriptionClass = getSubscriptionClassObject(subscriptions, scrolledChannel.publisherId);

                return (
                  <ChannelItem
                    subscriptionClass={subscriptionClass}
                    index={index}
                    channel={scrolledChannel}
                    imagesUrl={imagesUrl}
                    handleChannelClicked={(channel) => this.selectChannel(channel)}
                    selectedChannel={selectedChannel}
                    key={scrolledChannel.publisherId}
                  />
                );
              })}
            </InfiniteScroll>
          ) : null}
        </div>
        <div
          className="dropdown-button hasImage"
          style={{ borderTop: 'thin solid #c7c7c7', height: 70 }}
          onClick={() => handleCreateChannel()}
        >
          <i className="round-icon">+</i>
          <span
            className="innerText"
            style={{ color: '#06bb72', lineHeight: '70px' }}
          >
            create new
          </span>
        </div>
      </div>
    ) : (
      ''
    );
  };

  renderDropdownItems = () => {
    const { dropdownList } = this.props;

    return dropdownList.map((dropdownButton, i) => (!dropdownButton.isInvisible ? (
      <a
        className="dropdown-button"
        onClick={() => this.onRegularButtonClick(dropdownButton)}
        key={`${dropdownButton.menuOption + i}`}
      >
        <div className="innerText">{dropdownButton.text}</div>
      </a>
    ) : (
      ''
    )));
  };

  renderUserDropdownItems = () => {
    const {
      isAdmin,
      userName,
      clearCookie,
      dropdownList,
      openMenuLink,
      isChannelView,
      selectedChannel,
      handleSelectChannel,
      toggleChannelAndGroupPopup,
    } = this.props;
    const { isChannelListOpen } = this.state;
    if (!isChannelListOpen) {
      return (
        <>
          {isChannelView ? (
            <div
              className="dropdown-button border hasImage"
              style={{ height: 70 }}
              onClick={() => (isAdmin ? handleSelectChannel() : this.handleClickEditProfile())}
            >
              <div className="buttonImage">{this.profilePic()}</div>
              <div className="textWrapper">
                <div className="userNameText">{userName}</div>
                <div className="noteText">{isAdmin ? 'Back to user mode' : 'Edit your profile'}</div>
              </div>
            </div>
          ) : (
            ''
          )}
          {dropdownList.map((dropdownButton, i) => (!dropdownButton.isInvisible ? (
            <a
              className={`dropdown-button 
            ${dropdownButton.menuOption === 'logout' ? 'border' : ''}
            ${
              dropdownButton.iconType
                ? `hasArrow ic ${dropdownButton.iconType}`
                : ''
            }`}
              disabled={dropdownButton.isDisabled}
              onClick={() => {
                trackEvent(EVENT_TYPES.DROPDOWN_CLICKED, selectedChannel, dropdownButton.menuOption);
                if (dropdownButton.appName !== 'header') {
                  if (dropdownButton.menuOption === 'logout') {
                    clearCookie();
                  }
                  openMenuLink(
                    dropdownButton.appName,
                    dropdownButton.menuOption,
                    dropdownButton.menuOption === 'manageChannel'
                        && selectedChannel
                      ? selectedChannel.publisherId
                      : undefined,
                  );
                  return;
                }
                if (dropdownButton.menuOption === 'switchChannel') {
                  this.setState({ isChannelListOpen: true });
                } else {
                  toggleChannelAndGroupPopup(true);
                }
              }}
              key={`${dropdownButton.menuOption + i}`}
            >
              <div
                className="innerText"
                style={
                    dropdownButton.color ? { color: dropdownButton.color } : {}
                  }
              >
                {dropdownButton.text}
              </div>
            </a>
          ) : (
            ''
          )))}
        </>
      );
    }
    return '';
  };

  dropdownButton = () => {
    const { dropdownList, type } = this.props;
    if (dropdownList) {
      return (
        <div
          className="dropdown-container"
          style={{
            height: `${calcDropdownHeight(dropdownList)}px`,
          }}
        >
          <div className="dropdown">
            {this.renderChannels()}
            {type !== 'regular'
              ? this.renderUserDropdownItems()
              : this.renderDropdownItems()}
          </div>
        </div>
      );
    }
    return null;
  };

  render() {
    const {
      isSelected, selectedChannel, subscriptions, dropdownList,
    } = this.props;
    const showBadge = selectedChannel && getSubscriptionClassObject(subscriptions, selectedChannel.publisherId).type;

    return (
      <div
        className={`engine-button ${dropdownList ? 'has-dropdown' : ''} ${isSelected ? 'selected' : ''}`}
        style={showBadge ? { padding: '10px 18px 7px' } : {}}
      >
        {this.mainButton()}
        {this.dropdownButton()}
      </div>
    );
  }
}

MenuButton.propTypes = {
  openMenuLink: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
  profilePicUrl: PropTypes.string,
};

MenuButton.defaultProps = {
  isSelected: false,
  profilePicUrl: '',
};

export default MenuButton;
