import EventsTracker from '@apester/events';
import React from 'react';

import { getCrazyEggCustomParam, CE_CUSTOM_PARAMS } from '../utils/helpers';

/* eslint-disable max-len */
const getIframe = (publisher, sessionId, planId, crazyEggCustomPlan, paymentsUrl, user, origin) => {
  const userJson = JSON.stringify({
    userId: user.userId, userName: user.displayName, userEmail: user.emails[0], isKeepoKappa: user.isKeepoKappa,
  });
  const userQueryParam = window.btoa(unescape(encodeURIComponent(userJson)));
  return (`<iframe
    id="payments-header"
    scrolling="yes"
    src="${paymentsUrl}/pricing?publisherId=${publisher.publisherId}&origin=${origin}&publisherName=${publisher.name}&sessionId=${sessionId}${planId ? `&planId=${planId}` : ''}&inApp=true${crazyEggCustomPlan ? `&CECustomPlan=${crazyEggCustomPlan}` : ''}&user=${userQueryParam}"
    frameborder="no"></iframe>`
  );
};
/* eslint-enable max-len */

/* eslint-disable react/no-danger */
const PaymentPopup = ({
  publisher, shouldFadeIn, planId, paymentsUrl, user, origin,
}) => {
  const crazyEggCustomPlan = getCrazyEggCustomParam(CE_CUSTOM_PARAMS.USER_PLAN);
  const sessionId = EventsTracker.GetSessionId();
  return (
    <div
      className={`payments-wrapper-header ${shouldFadeIn ? 'fade-in' : 'fade-out'}`}
      dangerouslySetInnerHTML={{
        __html: getIframe(publisher, sessionId, planId, crazyEggCustomPlan, paymentsUrl, user, origin) || '',
      }}
    />
  );
};
/* eslint-enable react/no-danger */

export default PaymentPopup;
