const popupAttrs = {
  PUBLISH_INTERACTIONS_BLOCKED: {
    title: 'Give the people what they want: immersive content!',
    infoText: 'Your 14-day free trial is over. Upgrade now to unlock your account.',
    body: 'Regain access and engage, educate & entertain your audience with awesome content:',
    img: {
      name: 'block-interactions-img',
      height: '371px',
      background: 'yellow',
      right: '0px',
      bottom: '0px',
    },
    upgradeBtnPosition: {
      top: 'calc(50% + 148px)',
      left: 'calc(50% - 172px)',
    },
    origin: 'trial_ended_popup_publish_interactions',
    list: {
      color: 'yellow',
      bullets: ['Create an unlimited amount of units', 'Add brand colors and fonts', 'Generate leads'],
    },
    withClose: true,
  },
  TRIAL_ENDED: {
    title: 'It\'s upgrade time!',
    infoText: 'Your 14-day free trial is over. Upgrade now to unlock your account.',
    body: 'Choose your plan, upgrade today, and get back on track with the following:',
    img: {
      name: 'trial-ended-img',
      height: '326px',
      background: 'red',
      right: '22px',
      bottom: '61px',
    },
    upgradeBtnPosition: {
      top: 'calc(50% + 148px)',
      left: 'calc(50% - 172px)',
    },
    origin: 'trial_ended_popup',
    list: {
      color: 'yellow',
      bullets: ['Create unlimited fresh content', 'Segment audiences, collect data',
        'Generate quality leads', 'Many more exclusive features'],
    },
    withClose: true,
  },
  FB_POST_BLOCKED: {
    title: 'Post your experience to social for viral engagements',
    infoText: '',
    body: 'Publish to your Facebook business page with a single click',
    img: {
      name: 'block-interactions-img',
      height: '371px',
      background: 'yellow',
      right: '0px',
      bottom: '0px',
    },
    upgradeBtnPosition: {
      top: 'calc(50% + 148px)',
      left: 'calc(50% - 172px)',
    },
    origin: 'trial_ended_popup_publish_interactions',
    list: {
      color: 'yellow',
      bullets: [
        'One click post to social',
        'You decide to what page on your website your page fans will be redirected',
        'Increase your fans engagement by taking them to your desired page',
        'Easily edit you post visuals and text before posting',
      ],
    },
    withClose: true,
  },
  ANALYTICS_BLOCKED: {
    title: 'Your numbers won\'t crunch themselves!',
    infoText: 'Your 14-day free trial is over. Upgrade now to unlock your account.',
    body: `Use our premium analytics tool to make 
    data-driven decisions, boost performance & sales, and crush your goals:`,
    img: {
      name: 'block-analytics-img',
      height: '413px',
      background: 'violet',
      right: '0px',
      bottom: '0px',
    },
    upgradeBtnPosition: {
      top: 'calc(50% + 136px)',
      left: 'calc(50% - 172px)',
    },
    origin: 'analytics_blocked_popup',
    list: {
      color: 'violet',
      bullets: ['Get enhanced analytics capabilities', 'Learn more about your audience', 'Boost sales'],
    },
    withClose: false,
  },
  CUSTOM_ENGINE_BLOCKED: {
    title: 'Match Apester content to your brand needs',
    infoText: 'Contact us to get CSS and objects controls',
    img: {
      name: 'block-custom-engine-img',
      height: '422px',
      background: 'yellow',
      right: '-20px',
      bottom: '0px',
    },
    isContactUs: true,
    origin: 'analytics_blocked_popup',
    list: {
      color: 'violet',
      bullets: [
        'Styles control',
        'Overlays',
        'Special effects',
        'Multi answers',
        'Results screen control',
        'And many more...',
      ],
    },
    withClose: false,
  },
};

export default popupAttrs;
