import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import EventTracker, { HS } from '@apester/events';
// INNER DEPENDENCIES
import LogoHeader from './assets/logo_header@2x.png';
import initHeapIO from './HeapIO/heap.io';
import MenuButton from './MenuButton/MenuButton';
import AdminPopup from './popups/AdminPopup/AdminPopup';
import CreateEngineButton from './CreateEngineButton/CreateEngineButton';
import TrialNotification from './TrialNotification/TrialNotification';
import PublishViaSelector from './PublishViaSelector/PublishViaSelector';
import getMetadata from './metadata/header.data';
import {
  getUserSubscriptions,
  isAdmin,
  getCookie,
  setCookie,
  isEligibleForUpgrade,
  initCrazyEgg,
  getACGActiveJobs,
  isWixUser,
  getPublisherByInstanceId,
  isMobile,
  hasPublisherProPlan,
  getSidQueryString,
} from './utils/helpers';
import EVENT_TYPES, { trackEvent, trackACGEvent, initTrackingUser } from './utils/events';
import NoChannelPopup from './popups/NoChannelPopup/NoChannelPopup';
import RestrictionPopup from './popups/RestrictionPopup/RestrictionPopup';
import { initFlagr } from './Services/FlagrService/FlagrService';
import UpgradePlanButton from './UpgradePlanButton/UpgradePlanButton';
import popupAttrs from './popups/consts';
import COOKIE_NAMES from './utils/cookieConsts';
import ACGNotification from './ACGNotification/ACGNotification';
// STYLES
import './header.scss';

const { init: initHS } = HS;
const hasACGNotification = (activeACGJobs, currentUrl) => activeACGJobs
  && (activeACGJobs.length > 0 || currentUrl.includes('isACGFlow'));

const formatPreviewChannels = (user) => {
  if (!user.publishers || !user.publishers.length) {
    return [];
  }
  const channels = user.publishers;
  const defaultChannel = channels.find((channel) => user.defaultPublisher === channel.publisherId);
  if (defaultChannel) {
    const defaultChannelIndex = channels.indexOf(defaultChannel);
    const withoutDefault = [
      ...channels.slice(0, defaultChannelIndex),
      ...channels.slice(defaultChannelIndex + 1, channels.length),
    ];
    return [defaultChannel, ...withoutDefault];
  }
  return channels;
};

const navigateToOnboarding = ({
  company, companySize, jobTitle, vertical, needs,
}, portalUrl) => {
  if (isEmpty(company) || isEmpty(companySize) || isEmpty(jobTitle)) {
    window.location.replace(`${portalUrl}/onboarding/company${getSidQueryString(true)}`);
    return;
  }

  if (isEmpty(vertical)) {
    window.location.replace(`${portalUrl}/onboarding/vertical${getSidQueryString(true)}`);
    return;
  }

  if (isEmpty(needs)) {
    window.location.replace(`${portalUrl}/onboarding/goals${getSidQueryString(true)}`);
  }
};

const initializeSelectedChannel = (user, channelId, skipChannelCookie) => {
  if (skipChannelCookie && !channelId) {
    return undefined;
  }

  const isAdminUser = isAdmin(user);
  let selectedChannelId = skipChannelCookie ? channelId : getCookie(COOKIE_NAMES.CHANNEL);
  if (!isAdminUser && !selectedChannelId) {
    selectedChannelId = user.defaultPublisher;
  }
  let channel = (user.publishers || []).find(
    (chan) => selectedChannelId === chan.publisherId,
  );
  if (!channel && !isAdminUser) {
    channel = user.publishers ? user.publishers[0] : undefined;
  }
  return channel;
};

class Header extends Component {
  constructor(props) {
    super(props);
    const {
      user, sessionId, enableHeap, eventsUrl, skipChannelCookie, channelId,
    } = props;
    const metadata = sessionId ? { sessionId } : {};
    initHS();
    initHeapIO(user, enableHeap);
    EventTracker.Init({ metadata, eventsUrl });

    if (user) {
      this.channel = initializeSelectedChannel(user, channelId, skipChannelCookie);
      initFlagr(user);
      initTrackingUser(user);
    }

    this.headerSectionRef = createRef();
    this.state = {
      isChannelView: false,
      isOpenCreateEngineMenu: false,
      selectedChannel: undefined,
      filteredChannels: user && formatPreviewChannels(user),
      isAdminPopupView: false,
      showTrialEndedPopup: false,
      hasTrialNotification: undefined,
    };
  }

  async componentDidMount() {
    const {
      user, jobsUrl, planApi, portalUrl,
    } = this.props;
    if (!user) {
      return;
    }

    const channel = this.synchronizeSelectedChannel();

    const subscriptions = await getUserSubscriptions(user, planApi);
    const isPublisherPro = isAdmin(user) || hasPublisherProPlan(subscriptions);

    initCrazyEgg(user, subscriptions, channel);
    this.setState({ subscriptions }, this.setOldUpgradeButtonStatus);

    const publisherIdACG = (channel?.publisherId) || user.defaultPublisher;
    const activeACGJobs = await getACGActiveJobs(publisherIdACG, jobsUrl);
    this.setState({ activeACGJobs });

    if (!isMobile() && !user.isKeepoKappa && !isPublisherPro) {
      navigateToOnboarding(user, portalUrl);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      user, handleAcgNotification, skipChannelCookie, channelId,
    } = this.props;

    const { selectedChannel, activeACGJobs } = this.state;

    if (!selectedChannel && skipChannelCookie && channelId) {
      this.synchronizeSelectedChannel();
    }

    this.initHandleSwitchChannelProp(prevProps);

    if (user && !prevProps.user) {
      initTrackingUser(user);
    }

    if (prevProps.user && user.publishers !== prevProps.user.publishers) {
      const channel = (user.publishers || []).find(
        (chan) => selectedChannel && selectedChannel.publisherId === chan.publisherId,
      );
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        filteredChannels: formatPreviewChannels(user),
        selectedChannel: channel,
      }, this.setOldUpgradeButtonStatus);
    }

    const activeACGJobsChanged = handleAcgNotification && prevState.activeACGJobs !== activeACGJobs;
    const handleAcgNotificationChanged = handleAcgNotification && !prevProps.handleAcgNotification;
    if (activeACGJobsChanged || handleAcgNotificationChanged) {
      const showACGNotification = hasACGNotification(activeACGJobs, window.location.href);
      handleAcgNotification(showACGNotification);
    }
  }

  initHandleSwitchChannelProp = async (prevProps) => {
    const { selectedChannel } = this.state;
    const { handleSwitchChannel, usersUrl, skipChannelCookie } = this.props;
    if (!prevProps.handleSwitchChannel && handleSwitchChannel) {
      if (isWixUser()) {
        const publisher = await getPublisherByInstanceId(usersUrl);
        const publisherId = publisher ? publisher.publisherId : '';
        if (!publisherId) {
          return;
        }
        this.updateHeaderSelectedChannel(publisher, skipChannelCookie);
        handleSwitchChannel(publisherId);
      } else if (!selectedChannel) {
        handleSwitchChannel();
      } else {
        handleSwitchChannel(selectedChannel.publisherId);
      }
    }
  };

  handleSelectChannel = (channel, skipChannelCookie) => {
    this.channel = channel;
    const { handleSwitchChannel } = this.props;
    const { selectedChannel } = this.state;
    trackEvent(EVENT_TYPES.DROPDOWN_CLICKED, selectedChannel, 'selectChannel');
    const publisherId = channel ? channel.publisherId : '';
    if (!skipChannelCookie) {
      setCookie(COOKIE_NAMES.CHANNEL, publisherId, 90);
    }
    if (handleSwitchChannel) {
      handleSwitchChannel(channel ? channel.publisherId : undefined);
    }

    this.setState({
      selectedChannel: channel,
      isChannelView: !!channel,
    }, this.setOldUpgradeButtonStatus);
  };

  handleCreateChannel = () => {
    const { openMenuLink } = this.props;
    openMenuLink('console', 'createChannel');
  };

  handleHasTrialNotificationChanged = (isShown) => {
    this.setState({
      hasTrialNotification: isShown,
    });
  };

  toggleChannelAndGroupPopup = (isOpen) => {
    this.setState({
      isAdminPopupView: isOpen,
    });
  };

  renderAdminPopup = () => {
    const { isAdminPopupView } = this.state;
    const { openMenuLink, usersUrl } = this.props;
    if (isAdminPopupView) {
      return (
        <AdminPopup
          toggleChannelAndGroupPopup={this.toggleChannelAndGroupPopup}
          openMenuLink={openMenuLink}
          usersUrl={usersUrl}
        />
      );
    }
    return null;
  };

  filterChannels = (e) => {
    const { user } = this.props;
    const val = e && e.target && e.target.value;
    if (!val) {
      this.setState({
        filteredChannels: formatPreviewChannels(user),
      });
    } else {
      this.setState({
        filteredChannels: user.publishers.filter(
          (channel) => channel.name.toLowerCase().indexOf(val.toLowerCase()) > -1,
        ),
      });
    }
  };

  renderBlockingPopup = () => {
    const {
      popupType, callback, appName, user, editorUrl, paymentsUrl, wixIntegrationUrl, upgradeRedirectUrl,
    } = this.props;
    const { selectedChannel } = this.state;
    let popupObj;
    switch (popupType) {
      case 'NO_CHANNEL':
        return (
          <NoChannelPopup
            appName={appName}
            createNewChannel={() => callback()}
            onClose={() => this.closePopup()}
          />
        );
      case 'TRIAL_ENDED':
        popupObj = popupAttrs.PUBLISH_INTERACTIONS_BLOCKED;
        return (
          <RestrictionPopup
            wixIntegrationUrl={wixIntegrationUrl}
            paymentsUrl={paymentsUrl}
            upgradeRedirectUrl={upgradeRedirectUrl}
            editorUrl={editorUrl}
            popupObj={popupObj}
            user={user}
            publisher={selectedChannel}
            redirectTo={callback()}
            appName={appName}
            onClose={() => this.closePopup()}
          />
        );
      case 'FB_POST_BLOCKED':
        popupObj = popupAttrs.FB_POST_BLOCKED;
        return (
          <RestrictionPopup
            wixIntegrationUrl={wixIntegrationUrl}
            paymentsUrl={paymentsUrl}
            upgradeRedirectUrl={upgradeRedirectUrl}
            editorUrl={editorUrl}
            popupObj={popupObj}
            user={user}
            publisher={selectedChannel}
            appName={appName}
            onClose={() => this.closePopup()}
          />
        );
      case 'ANALYTICS_BLOCKED':
        popupObj = popupAttrs.ANALYTICS_BLOCKED;
        return (
          <RestrictionPopup
            wixIntegrationUrl={wixIntegrationUrl}
            paymentsUrl={paymentsUrl}
            upgradeRedirectUrl={upgradeRedirectUrl}
            editorUrl={editorUrl}
            popupObj={popupObj}
            user={user}
            publisher={selectedChannel}
            redirectTo={callback()}
            appName={appName}
            onClose={() => this.closePopup()}
          />
        );
      case 'CUSTOM_ENGINE_BLOCKED':
        popupObj = popupAttrs.CUSTOM_ENGINE_BLOCKED;
        return (
          <RestrictionPopup
            wixIntegrationUrl={wixIntegrationUrl}
            paymentsUrl={paymentsUrl}
            upgradeRedirectUrl={upgradeRedirectUrl}
            editorUrl={editorUrl}
            popupObj={popupObj}
            user={user}
            publisher={selectedChannel}
            appName={appName}
            onClose={() => this.closePopup()}
          />
        );
      default:
        return null;
    }
  };

  closePopup = () => {
    const { closePopup } = this.props;
    closePopup();
  };

  closeTrialEndedPopup = () => {
    this.setState({ showTrialEndedPopup: false });
  };

  setOldUpgradeButtonStatus = () => {
    const { user } = this.props;
    const { subscriptions, selectedChannel } = this.state;
    const isEligibleForUpgradeLegacyUsers = isEligibleForUpgrade(user, selectedChannel, subscriptions);
    this.setState({ isEligibleForUpgradeLegacyUsers });
  };

  handleEndTrial = (shouldRestrict, hasTrialNotification) => {
    const { handleEndTrial } = this.props;
    const { selectedChannel } = this.state;
    if (handleEndTrial) {
      handleEndTrial(shouldRestrict, hasTrialNotification);
    }

    if (shouldRestrict) {
      const trialPopupCookieKey = `${COOKIE_NAMES.APE_TRIAL_POPUP_SHOWED}_${selectedChannel.publisherId}`;
      const trialPopupAppeared = getCookie(trialPopupCookieKey);

      if (!trialPopupAppeared) {
        this.setState({ showTrialEndedPopup: true });
        setCookie(trialPopupCookieKey, true, 1);
      }
    }
  };

  updateHeaderSelectedChannel(channel, skipChannelCookie) {
    const publisherId = channel ? channel.publisherId : undefined;
    if (!skipChannelCookie) {
      setCookie(COOKIE_NAMES.CHANNEL, publisherId || '', 90);
    }
    this.setState({
      selectedChannel: channel,
      isChannelView: !!channel,
    });
  }

  synchronizeSelectedChannel() {
    const {
      user, channelId, skipChannelCookie, handleSwitchChannel,
    } = this.props;

    const channel = initializeSelectedChannel(user, channelId, skipChannelCookie);
    const publisherId = channel?.publisherId;
    this.updateHeaderSelectedChannel(channel, skipChannelCookie);
    if (handleSwitchChannel && publisherId) {
      handleSwitchChannel(publisherId);
    }

    return channel;
  }

  renderHeader() {
    if (isWixUser()) {
      return null;
    }
    const {
      isChannelView,
      selectedChannel,
      filteredChannels,
      subscriptions = [],
      hasTrialNotification,
      isEligibleForUpgradeLegacyUsers,
      activeACGJobs,
      isOpenCreateEngineMenu,
    } = this.state;
    const {
      openMenuLink,
      appName,
      user,
      profilePicUrl,
      imagesUrl,
      hideHeader,
      paymentsUrl,
      wixIntegrationUrl,
      upgradeRedirectUrl,
      displayChannelSelector,
      authUrl,
    } = this.props;

    if (hideHeader) {
      if (isAdmin(user) && !displayChannelSelector) {
        return null;
      }
      return (
        <>
          {this.renderBlockingPopup()}
          <PublishViaSelector
            hasTrialNotification={hasTrialNotification}
            hasACGNotification={hasACGNotification(activeACGJobs, window.location.href)}
            subscriptions={subscriptions}
            channels={filteredChannels}
            selectedChannel={selectedChannel}
            handleSelectChannel={this.handleSelectChannel}
            imagesUrl={imagesUrl}
          />
        </>
      );
    }
    if (!user || !openMenuLink) {
      return null;
    }
    const metadata = getMetadata(user, isChannelView);
    const { menuButtons, userButtons } = metadata;

    return (
      <div id="header" className="header">
        {this.renderBlockingPopup()}
        <div className="left-nav-bar">
          <img src={LogoHeader} className="logo" alt="" />
          {/* TODO: Logo will redirect to our website (when we will develop an option to be logged in on website.) */}
          {/* <a
            role="button"
            className="navbar-apester-logo"
            onClick={() => {
              trackEvent(EVENT_TYPES.BUTTON_CLICKED, selectedChannel, 'apesterLogo');
              openMenuLink('editor', 'dashboard');
            }}
            title="Apester"
            tabIndex="0"
          >
            <img src={LogoHeader} className="logo" alt="" />
          </a> */}
          <div className="buttons-list">
            {menuButtons.map((button) => (
              <MenuButton
                button={!button.dropdownList ? button : null}
                isSelected={appName === button.appName}
                openMenuLink={openMenuLink}
                type="regular"
                key={button.menuItemName}
                color={button.color}
                iconClass={button.iconClass}
                text={button.menuItemName}
                user={user}
                channel={selectedChannel}
                dropdownList={
                  button.dropdownList
                    ? button.dropdownList.map((dropdown) => ({
                      ...dropdown,
                      text: dropdown.menuItemName,
                    }))
                    : null
                }
                name={button.name}
              />
            ))}
          </div>
        </div>
        <div className="right-nav-bar">
          {hasTrialNotification === false && isEligibleForUpgradeLegacyUsers && (
            <UpgradePlanButton
              origin="legacy-users"
              text="upgrade"
              classNames="white-btn"
              user={user}
              publisher={selectedChannel}
              paymentsUrl={paymentsUrl}
              wixIntegrationUrl={wixIntegrationUrl}
              upgradeRedirectUrl={upgradeRedirectUrl}
            />
          )}
          <CreateEngineButton
            isOpen={isOpenCreateEngineMenu}
            onToggleMenu={(isOpen) => { this.setState({ isOpenCreateEngineMenu: isOpen }); }}
            trackButtonClicked={(tabName) => trackEvent(EVENT_TYPES.BUTTON_CLICKED, selectedChannel, tabName)}
            sectionRef={this.headerSectionRef}
            hasTrialNotification={hasTrialNotification}
            user={user}
            paymentsUrl={paymentsUrl}
            wixIntegrationUrl={wixIntegrationUrl}
            upgradeRedirectUrl={upgradeRedirectUrl}
            authUrl={authUrl}
            publisher={selectedChannel}
            openMenuLink={(menuOption) => {
              openMenuLink('editor', menuOption);
            }}
          />
          <div className="user-button">
            {userButtons.map((button) => (
              <MenuButton
                onMouseOver={() => {
                  this.setState({ isOpenCreateEngineMenu: false });
                }}
                user={user}
                isSelected={appName === button.appName}
                openMenuLink={openMenuLink}
                type="user"
                selectedChannel={selectedChannel}
                filteredChannels={filteredChannels}
                filterChannels={this.filterChannels}
                handleSelectChannel={this.handleSelectChannel}
                handleCreateChannel={this.handleCreateChannel}
                toggleChannelAndGroupPopup={this.toggleChannelAndGroupPopup}
                isChannelView={isChannelView}
                clearCookie={() => {
                  setCookie(COOKIE_NAMES.CHANNEL, '', 90);
                }}
                isAdmin={isAdmin(user)}
                userName={user.displayName}
                hasSearch={user.publishers && user.publishers.length > 10}
                profilePicUrl={profilePicUrl}
                imagesUrl={imagesUrl}
                key={button.menuItemName}
                color={button.color}
                iconClass={button.iconClass}
                text={button.menuItemName}
                subscriptions={subscriptions}
                dropdownList={
                  button.dropdownList
                    ? button.dropdownList.map((dropdown) => ({
                      ...dropdown,
                      text: dropdown.menuItemName,
                    }))
                    : null
                }
                name={button.name}
              />
            ))}
          </div>
        </div>
        {this.renderAdminPopup()}
      </div>
    );
  }

  renderTrialEndedPopup() {
    const {
      user, appName, editorUrl, paymentsUrl, wixIntegrationUrl, upgradeRedirectUrl
    } = this.props;
    const { selectedChannel, showTrialEndedPopup } = this.state;
    const popupObj = popupAttrs.TRIAL_ENDED;

    return (
      showTrialEndedPopup
        ? (
          <RestrictionPopup
            wixIntegrationUrl={wixIntegrationUrl}
            editorUrl={editorUrl}
            popupObj={popupObj}
            user={user}
            publisher={selectedChannel}
            appName={appName}
            onClose={() => this.closeTrialEndedPopup()}
            paymentsUrl={paymentsUrl}
            upgradeRedirectUrl={upgradeRedirectUrl}
          />
        ) : null
    );
  }

  renderTopBadge() {
    const {
      user, editorUrl, wixIntegrationUrl, paymentsUrl, upgradeRedirectUrl, jobsUrl,
    } = this.props;
    const { selectedChannel, subscriptions, activeACGJobs } = this.state;
    const currentUrl = window.location.href;
    const showACGNotification = hasACGNotification(activeACGJobs, currentUrl);
    if (!showACGNotification && (isAdmin(user) || !subscriptions)) {
      return null;
    }

    return showACGNotification ? (
      <ACGNotification
        editorUrl={editorUrl}
        wixIntegrationUrl={wixIntegrationUrl}
        jobsUrl={jobsUrl}
        activeJobs={activeACGJobs || []}
        isUserInDashboard={currentUrl.includes('dashboard')}
        closeACGNotification={() => {
          this.setState({ activeACGJobs: [] });
        }}
        trackEvent={(name, rawData) => {
          const { publisherId } = selectedChannel || this.channel;
          const data = { publisherId, ...rawData };
          trackACGEvent(name, data);
        }}
        publisherId={selectedChannel.publisherId}
      />
    ) : (
      <TrialNotification
        paymentsUrl={paymentsUrl}
        wixIntegrationUrl={wixIntegrationUrl}
        upgradeRedirectUrl={upgradeRedirectUrl}
        subscriptions={subscriptions}
        user={user}
        handleEndTrial={this.handleEndTrial}
        channel={selectedChannel || this.channel}
        handleHasTrialNotificationChanged={this.handleHasTrialNotificationChanged}
      />
    );
  }

  render() {
    const { classNames } = this.props;
    return (
      <div ref={this.headerSectionRef} id="header-section" className={classNames}>
        {this.renderTopBadge()}
        {this.renderTrialEndedPopup()}
        {this.renderHeader()}
      </div>
    );
  }
}

Header.propTypes = {
  hideHeader: PropTypes.bool,
  closePopup: PropTypes.func,
  callback: PropTypes.func,
  openMenuLink: PropTypes.func,
  user: PropTypes.instanceOf(Object),
  profilePicUrl: PropTypes.string,
  handleSwitchChannel: PropTypes.func,
  classNames: PropTypes.string,
  sessionId: PropTypes.string,
};

Header.defaultProps = {
  hideHeader: false,
  closePopup: undefined,
  callback: undefined,
  openMenuLink: undefined,
  profilePicUrl: '',
  user: undefined,
  handleSwitchChannel: undefined,
  classNames: '',
  sessionId: '',
};

export default Header;
