import React, { Component, createRef } from 'react';
import ChannelItem from '../ChannelItem/ChannelItem';
import { getSubscriptionClassObject } from '../utils/helpers';

import TRIAL_NOTIFICATION_HEIGHT from './consts';

class PublishViaSelector extends Component {
  constructor(props) {
    super(props);

    this.publishViaRef = createRef();
    this.state = {
      isOpen: false,
    };
  }

  componentDidUpdate() {
    const { hasTrialNotification, hasACGNotification } = this.props;
    this.publishViaRef.current.style.top = hasTrialNotification || hasACGNotification ? TRIAL_NOTIFICATION_HEIGHT : 0;
  }

  toggleChannelSelector = () => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  };

  render() {
    const { isOpen } = this.state;
    const {
      channels, selectedChannel, handleSelectChannel, imagesUrl, subscriptions,
    } = this.props;
    const badgeClass = selectedChannel ? getSubscriptionClassObject(subscriptions, selectedChannel._id).type : '';
    const showBadge = selectedChannel && badgeClass;

    return (
      <div id="channel-selector" ref={this.publishViaRef}>
        <div
          className={`image-wrapper ${showBadge ? `plans-${badgeClass}` : 'grey-border'}`}
          onClick={() => this.toggleChannelSelector()}
        >
          {selectedChannel ? (
            <img
              alt=""
              className="ic"
              src={`${imagesUrl}/insecure/fit/0/36/ce/0/plain/${encodeURIComponent(
                selectedChannel.imagePath
                                  || (selectedChannel.profileImage
                                    && selectedChannel.profileImage.path),
              )}`}
            />
          ) : null}

        </div>
        { showBadge
          && (
          <div className={`badge badge__${badgeClass}`}>
            <i className="ic icon-star" />
          </div>
          )}
        {isOpen ? (
          <>
            <div className="overlay" onClick={() => this.toggleChannelSelector()} />
            <div className="channel-selector-dropdown">
              <div className="fixed-button">
                <i className="ic icon-publish-via" />
                <span className="text">Publish Via</span>
              </div>
              {channels.map((item, index) => {
                const subscriptionClass = getSubscriptionClassObject(subscriptions, item._id);
                const handleSelectChannelAndToggle = (channel) => {
                  this.toggleChannelSelector();
                  handleSelectChannel(channel);
                };

                return (
                  <ChannelItem
                    subscriptionClass={subscriptionClass}
                    index={index}
                    channel={item}
                    imagesUrl={imagesUrl}
                    selectedChannel={selectedChannel}
                    handleChannelClicked={(channel) => handleSelectChannelAndToggle(channel)}
                    key={item._id}
                  />
                );
              })}
            </div>
          </>
        ) : null}
      </div>
    );
  }
}

export default PublishViaSelector;
