import {
  isAdmin,
} from '../utils/helpers';

const hasChannels = (user) => Boolean((user.publishers || []).length);

const getMetadata = (userData, isChannelView) => {
  const metadata = {
    menuButtons: [{
      menuOption: 'dashboard',
      menuItemName: 'dashboard',
      appName: 'editor',
    },
    {
      menuOption: 'templates',
      menuItemName: 'templates',
      appName: 'portal',
      dropdownList: [{
        appName: 'portal',
        menuOption: 'all',
        menuItemName: 'all',
      },
      {
        appName: 'portal',
        menuOption: 'sport-gaming',
        menuItemName: 'sport & gaming',
      },
      {
        appName: 'portal',
        menuOption: 'news-entertainment',
        menuItemName: 'news & entertainment',
      },
      {
        appName: 'portal',
        menuOption: 'finance',
        menuItemName: 'finance',
      },
      {
        appName: 'portal',
        menuOption: 'technology-commerce',
        menuItemName: 'tech & commerce',
      },
      ],
    },
    {
      appName: 'analytics',
      menuOption: 'analytics',
      menuItemName: 'analytics',
    },
    ],
    userButtons: [{
      menuOption: 'user',
      menuItemName: 'user',
      appName: 'console',
      dropdownList: [{
        appName: 'console',
        menuOption: 'editProfile',
        menuItemName: 'edit profile',
        isInvisible: isChannelView,
      },
      {
        appName: 'console',
        menuOption: 'manageChannel',
        menuItemName: 'manage channel',
        isInvisible: !hasChannels(userData) || !isChannelView,
      },
      {
        appName: 'header',
        menuOption: 'switchChannel',
        menuItemName: 'switch to channel',
        isInvisible: !hasChannels(userData),
        iconType: 'icon-arrow-right',
      },
      {
        appName: 'header',
        menuOption: 'allChannels&Groups',
        menuItemName: 'all channels & groups',
        isInvisible: !isAdmin(userData),
      },
      {
        appName: 'console',
        menuOption: 'createChannel',
        menuItemName: 'create channel',
        isInvisible: hasChannels(userData),
        iconType: 'icon-plus',
      },
      {
        appName: 'console',
        menuOption: 'createGroup',
        menuItemName: 'create group',
        isInvisible: !isAdmin(userData),
      },
      {
        appName: 'help',
        menuOption: 'help',
        menuItemName: 'help',
      },
      {
        appName: 'editor',
        menuOption: 'logout',
        menuItemName: 'logout',
        color: 'red',
      },
      {
        appName: 'apester',
        menuOption: 'contact',
        menuItemName: 'contact',
      },
      {
        appName: 'apester',
        menuOption: 'mainSite',
        menuItemName: 'apester.com',
      },
      ],
    }],
  };
  if (isAdmin(userData)) {
    metadata.menuButtons.push({
      menuOption: 'internal',
      menuItemName: 'internal',
      appName: 'internal',
      dropdownList: [{
        appName: 'campaign',
        menuOption: 'programaticCampaigns',
        menuItemName: 'Programatic Campaigns',
      },
      {
        appName: 'campaign',
        menuOption: 'brandedCampaigns',
        menuItemName: 'Branded Campaigns',
      },
      {
        appName: 'adset',
        menuOption: 'ads',
        menuItemName: 'Ads.txt',
      },
      {
        appName: 'adset',
        menuOption: 'allAdsets',
        menuItemName: 'Adset manager - Beta',
      },
      ],
    });
  }

  return metadata;
};

export default getMetadata;
