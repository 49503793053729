import EventTracker from '@apester/events';
import { getFeature } from '../Services/FlagrService/FlagrService';

export function buildElement(type) {
  return (attributes) => {
    const element = document.createElement(type);
    Object.entries(attributes).forEach(([k, v]) => {
      element[k] = v;
    });
    return element;
  };
}

const msToDays = (ms) => Math.floor(ms / (1000 * 60 * 60 * 24));

const subscriptionsPlans = {
  MARKETER_PRO: 'Marketer Pro',
  PUBLISHER_PRO: 'Publisher Pro',
  ESSENTIAL: 'Essential',
  PROFESSIONAL: 'Professional',
  PROFESSIONAL_V2: 'ProfessionalV2',
  PROFESSIONAL_V3: 'ProfessionalV3',
  BUSINESS: 'Business',
  BUSINESS_V3: 'BusinessV3',
  UNLIMITED: 'Unlimited',
  ENTERPRISE: 'Enterprise',
  STARTER: 'Starter',
  ENGAGEMENT: 'Engagement',
  SUBSCRIBERS: 'Subscribers',
  GROWTH: 'Growth',
  TRIAL: 'Trial',
};

export const THIRD_PARTY_TRIAL_PLANS = {
  WIX_TRIAL: 'wix',
};

const TRIAL_PLANS = [subscriptionsPlans.TRIAL, THIRD_PARTY_TRIAL_PLANS.WIX_TRIAL];

export const getMainDomainName = () => {
  if (!window.location || !window.location.hostname) {
    return 'apester.com';
  }
  const domain = window.location.hostname;
  return domain.substring(domain.indexOf('.') + 1);
};

export const TRIAL_MODES = {
  NONE: 'NONE',
  ON_TRIAL: 'ON_TRIAL',
  TRIAL_ENDED: 'TRIAL_ENDED',
  LEGACY_ENDED: 'LEGACY_ENDED',
  LEGACY_WARNING: 'LEGACY_WARNING',
  FB_POST_BLOCKED: 'FB_POST_BLOCKED',
};

const noSubscriptionsForChannel = (subscriptions, channel) => {
  if (!channel || !subscriptions) {
    return true;
  }
  return !subscriptions
    .some((subscription) => subscription.subscriptionEntities[0].entityId === channel.publisherId);
};

export const isAdmin = (user) => user && user.roles && user.roles.includes('admin');

export const isEligibleForUpgrade = (user, channel, subscriptions) => {
  if (isAdmin(user) || !subscriptions) {
    return false;
  }
  if (!subscriptions.length) {
    return true;
  }
  const channelHasSubscription = subscriptions
    .find((subscription) => subscription.subscriptionEntities[0].entityId === channel.publisherId
    // eslint-disable-next-line max-len
            && !(TRIAL_PLANS.some((plan) => plan.toLowerCase() === subscription.billingPlan.productSet.name.toLowerCase())));
  return !channelHasSubscription;
};

const getChannelTrialStartDate = (channel, subscriptions) => {
  if (!subscriptions || !subscriptions.length) {
    return new Date(channel.created);
  }

  const latestTrial = subscriptions
    .filter((subscription) => subscription.subscriptionEntities[0].entityId === channel.publisherId
            && subscription.billingPlan.productSet.name.toLowerCase() === subscriptionsPlans.TRIAL.toLowerCase())
    .sort((trialA, trialB) => trialB.startDate - trialA.startDate)[0];

  if (!latestTrial) {
    return new Date(channel.created);
  }

  return new Date(latestTrial.startDate);
};

export const getUserSubscriptions = async (user, plansApiUrl) => {
  if (isAdmin(user)) {
    return [];
  }
  try {
    const subscriptionsRes = await fetch(`${plansApiUrl}/subscriptions`, { credentials: 'include' });
    return subscriptionsRes.json();
  } catch (e) {
    return [];
  }
};

export const persistSelectedSubscription = (subscriptions, selectedChannelId) => {
  if (!subscriptions || !selectedChannelId) {
    return undefined;
  }

  const filteredSubscriptions = subscriptions.map((subscription) => ({
    plan: subscription.billingPlan.productSet,
    channelId: subscription.subscriptionEntities[0].entityId,
  }));

  return filteredSubscriptions?.find((subscription) => subscription.channelId === selectedChannelId);
};

export const getSubscriptionClassObject = (subscriptions, id) => {
  const subscription = persistSelectedSubscription(subscriptions, id);

  // type is used to choose the plan's badge.
  if (subscription) {
    switch (subscription.plan?.name) {
      case (subscriptionsPlans.ENGAGEMENT):
        return { type: 'essential', name: 'engagement' };
      case (subscriptionsPlans.SUBSCRIBERS):
        return { type: 'professional', name: 'subscribers' };
      case (subscriptionsPlans.GROWTH):
        return { type: 'enterprise', name: 'growth' };
      case (subscriptionsPlans.PROFESSIONAL):
      case (subscriptionsPlans.PROFESSIONAL_V2):
      case (subscriptionsPlans.PROFESSIONAL_V3):
        return { type: 'professional', name: 'professional' };
      case (subscriptionsPlans.BUSINESS):
      case (subscriptionsPlans.BUSINESS_V3):
        return { type: 'publisher', name: 'business' };
      case (subscriptionsPlans.UNLIMITED):
        return { type: 'publisher', name: 'unlimited' };
      case (subscriptionsPlans.MARKETER_PRO):
        return { type: 'publisher', name: 'premium' };
      case (subscriptionsPlans.PUBLISHER_PRO):
        return { type: 'publisher', name: 'publisher' };
      case (subscriptionsPlans.ENTERPRISE):
        return { type: 'enterprise', name: 'enterprise' };
      case (subscriptionsPlans.ESSENTIAL):
        return { type: 'essential', name: 'essential' };
      case (subscriptionsPlans.STARTER):
        return { type: 'essential', name: 'starter' };
      default:
        return { type: '' };
    }
  } else {
    return { type: '' };
  }
};

export const hasPublisherProPlan = (subscriptions) => (
  subscriptions.some((sub) => sub?.billingPlan?.productSet?.name === subscriptionsPlans.PUBLISHER_PRO)
);

export const setCookie = (cname, cvalue, exdays) => {
  const hostName = getMainDomainName();
  const date = new Date();
  date.setTime(date.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires=${date.toUTCString()}`;
  const domain = `domain=${hostName}`;
  document.cookie = `${cname}=${cvalue};${expires};${domain};path=/`;
};

export const getCookie = (cname) => {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

const getTrialModeFromTimePassed = (timePassed, maxTrialDays) => {
  const diffDays = msToDays(timePassed);
  if (diffDays >= maxTrialDays) {
    return { mode: TRIAL_MODES.TRIAL_ENDED };
  }
  return { mode: TRIAL_MODES.ON_TRIAL, daysLeft: maxTrialDays - diffDays };
};

const shouldBeHandledAsLegacyUser = (channel, user, subscriptions, globalStartDate) => {
  const userCreatedDate = new Date(user.created);
  return userCreatedDate < globalStartDate && noSubscriptionsForChannel(subscriptions, channel);
};

const getLegacyMode = (legacyEnd) => {
  if (!legacyEnd) {
    return { mode: TRIAL_MODES.NONE };
  }
  const legacyEndDate = new Date(legacyEnd);
  if (Number.isNaN(legacyEndDate.getTime())) {
    return { mode: TRIAL_MODES.NONE };
  }
  const now = new Date();
  if (now < legacyEndDate) {
    return { mode: TRIAL_MODES.LEGACY_WARNING, legacyBlockDate: legacyEnd };
  }
  return { mode: TRIAL_MODES.LEGACY_ENDED };
};

export const getCurrentTrialMode = (
  channel,
  user,
  subscriptions,
  globalStartDate,
  maxTrialDays,
  legacyEnd,
) => {
  if (shouldBeHandledAsLegacyUser(channel, user, subscriptions, globalStartDate)) {
    return getLegacyMode(legacyEnd);
  }
  if (!channel) {
    return { mode: TRIAL_MODES.NONE };
  }
  const isEligible = isEligibleForUpgrade(user, channel, subscriptions) && maxTrialDays > 0 && globalStartDate;
  if (!isEligible) {
    return { mode: TRIAL_MODES.NONE };
  }
  const now = new Date();
  const trialStartDate = getChannelTrialStartDate(channel, subscriptions);
  if (globalStartDate > now) {
    return { mode: TRIAL_MODES.NONE };
  }
  if (trialStartDate > globalStartDate) {
    return getTrialModeFromTimePassed(now - trialStartDate, maxTrialDays);
  }
  return getTrialModeFromTimePassed(now - globalStartDate, maxTrialDays);
};

export const isBlocked = (currentMode) => currentMode === TRIAL_MODES.TRIAL_ENDED
    || currentMode === TRIAL_MODES.LEGACY_ENDED;

export const getTrialSettingsData = async (user, channel, subscriptions) => {
  const flag = await getFeature('trial_settings');
  if (!flag) {
    return undefined;
  }

  const {
    startYear,
    startMonth,
    startDay,
    daysOfTrial: maxTrialDays,
    legacyEnd: legacyBlockDate,
  } = flag.variantAttachment;

  const globalTrialStartDate = new Date(startYear, startMonth - 1, startDay);

  const currentTrialConfig = getCurrentTrialMode(
    channel,
    user,
    subscriptions,
    globalTrialStartDate,
    maxTrialDays,
    legacyBlockDate,
  );

  return {
    maxTrialDays,
    legacyBlockDate,
    currentTrialConfig,
    globalTrialStartDate,
  };
};

const isDevEnvironment = process.env.NODE_ENV !== 'production';

const isInternalUser = (user) => isDevEnvironment || (user && isAdmin(user));

const getCEPlanParam = (planName) => {
  const {
    MARKETER_PRO, PUBLISHER_PRO, ESSENTIAL, PROFESSIONAL, BUSINESS, UNLIMITED, ENTERPRISE,
    TRIAL, STARTER, ENGAGEMENT, SUBSCRIBERS, GROWTH,
  } = subscriptionsPlans;
  switch (planName) {
    case MARKETER_PRO:
      return 'Marketer pro';
    case PUBLISHER_PRO:
      return 'Publishers';
    case TRIAL:
    case TRIAL_MODES.ON_TRIAL:
    case TRIAL_MODES.LEGACY_WARNING:
      return 'Trial';
    case TRIAL_MODES.TRIAL_ENDED:
    case TRIAL_MODES.LEGACY_ENDED:
      return 'Blocked';
    case ESSENTIAL:
    case STARTER:
    case PROFESSIONAL:
    case BUSINESS:
    case UNLIMITED:
    case ENTERPRISE:
    case ENGAGEMENT:
    case SUBSCRIBERS:
    case GROWTH:
      return 'Premium';
    default:
      return planName || TRIAL_MODES.NONE;
  }
};

export const CE_CUSTOM_PARAMS = {
  USER_PLAN: 1,
};

export const getCrazyEggCustomParam = (paramName) => {
  if (!window.CE2 || !window.CE2.userData) {
    return undefined;
  }
  return window.CE2.userData[paramName];
};

export const initCrazyEgg = (user, subscriptions, channel = {}) => {
  if (isInternalUser(user)) {
    return;
  }

  if (user) {
    window.CE_READY = async () => {
      window.CE2.identify(user.emails[0]);
      const { publisherId } = channel;
      const subscription = persistSelectedSubscription(subscriptions, publisherId);
      let planName = subscription?.plan?.name;

      if (planName === subscriptionsPlans.TRIAL || !planName) {
        const trialSettingsData = await getTrialSettingsData(user, channel, subscriptions);
        if (!trialSettingsData) {
          return;
        }
        const { currentTrialConfig } = trialSettingsData;
        planName = currentTrialConfig.mode;
      }

      window.CE2.set(CE_CUSTOM_PARAMS.USER_PLAN, getCEPlanParam(planName));
    };
  }

  const headTag = document.getElementsByTagName('head')[0];
  const scriptTag = document.createElement('script');
  scriptTag.async = true;
  scriptTag.src = 'https://script.crazyegg.com/pages/scripts/0096/8556.js';
  headTag.appendChild(scriptTag);
};

const fetchJobsApi = async (url) => {
  try {
    const statusJob = await fetch(
      url,
      {
        method: 'GET',
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      },
    );
    return statusJob.json();
  } catch (e) {
    return undefined;
  }
};

export const getACGActiveJobs = async (selectedChannelId, jobsUrl) => {
  const url = new URL(`${jobsUrl}/jobs/`);
  url.searchParams.append('entity', `publisher,${selectedChannelId}`);
  url.searchParams.append('status', 'pending');
  return fetchJobsApi(url);
};

export const getACGJob = async (jobId, jobsUrl) => fetchJobsApi(`${jobsUrl}/jobs/${jobId}`);

const getQueryParam = (param) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
};

export const getSidQueryString = (isFirstParam = false) => {
  const sessionId = EventTracker.GetSessionId();
  return sessionId ? `${isFirstParam ? '?' : '&'}sId=${sessionId}` : '';
};

// Has difference between user model and publisher model.
const isThirdPartyWix = (selectedChannel) => {
  const { thirdParty } = selectedChannel;
  const type = typeof thirdParty === 'object' ? thirdParty.thirdPartyType : thirdParty;
  return type === THIRD_PARTY_TRIAL_PLANS.WIX_TRIAL;
};

export const isWixUser = (selectedChannel) => (
  selectedChannel ? isThirdPartyWix(selectedChannel) : getQueryParam('isWixUser')
);

export const getPublisherByInstanceId = async (usersUrl) => {
  const instanceId = getQueryParam('instanceId');
  const publisher = await fetch(`${usersUrl}/publisher/thirdParty/${instanceId}`, { credentials: 'include' });
  if (publisher) {
    const publisherData = await publisher.json();
    return publisherData.payload;
  }
  return null;
};

export const redirectWixUserToPayments = async (channelId, wixIntegrationUrl) => {
  try {
    const res = await fetch(`${wixIntegrationUrl}/payments?channelId=${channelId}`, {
      method: 'GET',
    });
    const { payload } = await res.json();
    const { paymentsUrl } = payload || {};
    if (paymentsUrl) {
      window.location.href = paymentsUrl;
    }
    return undefined;
  } catch (e) {
    return undefined;
  }
};

const DESKTOP_SCREEN_WIDTH = 1024;

export const isMobile = () => (
  window?.screen.availWidth < DESKTOP_SCREEN_WIDTH
);
