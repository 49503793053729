import React, { Component } from 'react';
import alert from '../assets/alert.svg';
import {
  getCurrentTrialMode, TRIAL_MODES, getTrialSettingsData, isBlocked,
} from '../utils/helpers';
import UpgradePlanButton from '../UpgradePlanButton/UpgradePlanButton';

class TrialNotification extends Component {
  constructor(props) {
    super(props);
    this.maxTrialDays = -1;
    this.globalTrialStartDate = undefined;
    this.state = {
      trialConfig: { mode: TRIAL_MODES.NONE },
    };
  }

  async componentDidMount() {
    const {
      user, channel, handleEndTrial, subscriptions, handleHasTrialNotificationChanged,
    } = this.props;

    const trialSettingsData = await getTrialSettingsData(user, channel, subscriptions);
    if (!trialSettingsData) {
      return;
    }
    const {
      maxTrialDays,
      legacyBlockDate,
      globalTrialStartDate,
      currentTrialConfig: newTrialConfig,
    } = trialSettingsData;
    this.maxTrialDays = maxTrialDays;
    this.globalTrialStartDate = globalTrialStartDate;
    this.setState({
      legacyBlockDate,
      trialConfig: newTrialConfig,
    });
    const shouldRestrict = isBlocked(newTrialConfig.mode);
    const hasTrialNotification = newTrialConfig.mode && newTrialConfig.mode !== TRIAL_MODES.NONE;
    handleHasTrialNotificationChanged(hasTrialNotification);
    if (handleEndTrial) {
      handleEndTrial(shouldRestrict, hasTrialNotification);
    }
  }

  componentDidUpdate(prevProps) {
    const { legacyBlockDate } = this.state;
    const {
      subscriptions, handleEndTrial, channel, user, handleHasTrialNotificationChanged,
    } = this.props;

    if (prevProps.channel !== channel || prevProps.subscriptions !== subscriptions) {
      const newTrialConfig = getCurrentTrialMode(
        channel,
        user,
        subscriptions,
        this.globalTrialStartDate,
        this.maxTrialDays,
        legacyBlockDate,
      );

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(
        { trialConfig: newTrialConfig },
      );
      const shouldRestrict = isBlocked(newTrialConfig.mode);
      const hasTrialNotification = newTrialConfig.mode && newTrialConfig.mode !== TRIAL_MODES.NONE;
      handleHasTrialNotificationChanged(hasTrialNotification);
      if (handleEndTrial) {
        handleEndTrial(shouldRestrict, hasTrialNotification);
      }
    }
  }

  getTrialLabel() {
    const { trialConfig } = this.state;
    switch (trialConfig.mode) {
      case TRIAL_MODES.ON_TRIAL:
        return this.renderOnTrialMode();
      case TRIAL_MODES.TRIAL_ENDED:
        return this.renderTrialEndedMode();
      case TRIAL_MODES.LEGACY_WARNING:
        return this.renderOnLegacyWarning();
      case TRIAL_MODES.LEGACY_ENDED:
        return this.renderOnLegacyEnded();
      default:
        return '';
    }
  }

  renderTrialEndedMode = () => {
    const {
      user, channel, paymentsUrl, wixIntegrationUrl, upgradeRedirectUrl,
    } = this.props;
    return (
      <>
        <img alt="alert" className="label-icon" src={alert} />
        <div className="default-label main-label">Your trial period has ended!</div>
        <div className="default-label">To create new content and publish it on your site</div>

        <UpgradePlanButton
          classNames="white-btn blocked"
          origin="trial_ended_toast"
          user={user}
          publisher={channel}
          paymentsUrl={paymentsUrl}
          wixIntegrationUrl={wixIntegrationUrl}
          upgradeRedirectUrl={upgradeRedirectUrl}
        />
      </>
    );
  };

  renderOnLegacyWarning = () => {
    const { legacyBlockDate } = this.state;
    const {
      user, channel, paymentsUrl, wixIntegrationUrl, upgradeRedirectUrl,
    } = this.props;
    return (
      <>
        <img alt="alert" className="label-icon" src={alert} />
        <div className="default-label main-label">
          {`Hey there! Your free Apester account will be locked on ${legacyBlockDate}, so hurry and`}
        </div>
        <UpgradePlanButton
          origin="legacy_warning_toast"
          user={user}
          publisher={channel}
          paymentsUrl={paymentsUrl}
          wixIntegrationUrl={wixIntegrationUrl}
          upgradeRedirectUrl={upgradeRedirectUrl}
        />
      </>
    );
  };

  renderOnLegacyEnded = () => {
    const {
      user, channel, paymentsUrl, wixIntegrationUrl, upgradeRedirectUrl,
    } = this.props;
    return (
      <>
        <img alt="alert" className="label-icon" src={alert} />
        <div className="default-label main-label">
          Hey there! This account is now locked. Check your email for a special offer from Apester or
        </div>
        <UpgradePlanButton
          classNames="white-btn blocked"
          origin="legacy_blocked_toast"
          user={user}
          publisher={channel}
          paymentsUrl={paymentsUrl}
          wixIntegrationUrl={wixIntegrationUrl}
          upgradeRedirectUrl={upgradeRedirectUrl}
        />
      </>
    );
  };

  renderOnTrialMode() {
    const {
      user, channel, paymentsUrl, wixIntegrationUrl, upgradeRedirectUrl,
    } = this.props;
    const { trialConfig } = this.state;
    return (
      <div className="on-trial">
        <div>
          <i className="label-icon"><span role="img" aria-label="hola">👋</span></i>
          <div className="default-label main-label">{`${trialConfig.daysLeft} days left in your free trial.`}</div>
          <div className="default-label">Upgrade now to unlock Apester experience</div>
        </div>
        <div>
          <UpgradePlanButton
            origin="on_trial_toast"
            user={user}
            publisher={channel}
            paymentsUrl={paymentsUrl}
            wixIntegrationUrl={wixIntegrationUrl}
            upgradeRedirectUrl={upgradeRedirectUrl}
          />
        </div>
      </div>
    );
  }

  render() {
    const { trialConfig } = this.state;

    return (
      trialConfig.mode !== TRIAL_MODES.NONE
        ? (
          <div
            id="notification"
            className={`${isBlocked(trialConfig.mode) ? 'blocked' : ''}`}
          >
            {this.getTrialLabel()}
          </div>
        )
        : null
    );
  }
}

export default TrialNotification;
