import { EventsTracker, HS } from '@apester/events';

const eventTypes = {
  TEMPLATE_PAGE_CLICKED: 'template_page_clicked',
  DROPDOWN_CLICKED: 'dropdown_clicked',
  PAYMENT_UPGRADE_CLICKED: 'payment_upgrade_clicked',
  BUTTON_CLICKED: 'button_clicked',
  ACG_PROCESS_COMPLETED: 'acg_process_completed',
  ACG_STORY_FAILED: 'acg_story_failed',
  UPGRADE_POPUP_LOADED: 'upgrade_popup_loaded',
  UPGRADE_POPUP_CLICKED_CLOSE: 'upgrade_popup_clicked_close',
  UPGRADE_POPUP_CLICKED_BACK_TO_DASHBOARD: 'upgrade_popup_clicked_back_to_dashboard',
  TRY_AUTO_POLL: 'try_auto_poll',
};

const defaultEventProperties = {
  type: 'element_clicked',
  action: 'click',
  element: 'button',
  category: 'manage',
};

export const trackEvent = (name, publisher, tabName, eventProperties = {}) => {
  EventsTracker.SendFunnelEvent({
    name,
    tab: tabName,
    ...defaultEventProperties,
    ...eventProperties,
    properties: {
      publisherId: publisher?.publisherId,
    },
  });
};

export const trackACGEvent = (name, data) => {
  EventsTracker.SendFunnelEvent({
    name,
    type: 'page_loaded',
    element: 'action',
    category: 'create',
    properties: {
      ...data,
    },
  });
};

export const initTrackingUser = (user) => {
  HS.identifyUser(user.emails[0]);
  EventsTracker.IdentifyUser(user);
};

export default eventTypes;
