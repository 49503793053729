export const ENGINES = {
  POLL: 'poll',
  COUNTDOWN: 'countdown',
  VIDEOPOLL: 'videoPoll',
  AUTOPOLL: 'autoPoll',
  AUTOQUIZ: 'autoQuiz',
  STORY: 'story',
  GALLERY: 'gallery',
  CUSTOM: 'custom',
  QUIZ: 'quiz',
  PERSONALITY: 'personality',
  VIDEOQUIZ: 'videoQuiz',
  VIDEOPERSONALITY: 'videoPersonality'
}

const engineList = [
  {
    description: 'polls',
    engines: [
      { label: 'poll', icon: 'poll', action: 'poll', name: ENGINES.POLL },
      { label: 'countdown', icon: 'countdown', action: 'countdown', name: ENGINES.COUNTDOWN },
      { label: 'video poll', icon: 'video', action: 'videoPoll', name: ENGINES.VIDEOPOLL },
      { label: 'auto poll', icon: 'auto-poll', action: 'autoPoll', name: ENGINES.AUTOPOLL },
    ],
  },
  {
    description: 'rich content',
    engines: [
      { label: 'story', icon: 'story', action: 'story', name: ENGINES.STORY },
      { label: 'gallery', icon: 'gallery', action: 'gallery', name: ENGINES.GALLERY },
      { label: 'custom', icon: 'custom', action: 'custom', name: ENGINES.CUSTOM },
    ],
  },
  {
    description: 'quizzes & tests',
    engines: [
      { label: 'quiz', icon: 'countdown', action: 'quiz', name: ENGINES.QUIZ },
      { label: 'personality', icon: 'personality', action: 'personality', name: ENGINES.PERSONALITY },
      { label: 'video quiz', icon: 'video', action: 'videoQuiz', name: ENGINES.VIDEOQUIZ },
      { label: 'vid. personality', icon: 'video', action: 'videoPersonality', name: ENGINES.VIDEOPERSONALITY },
      { label: 'auto quiz', icon: 'countdown', action: 'autoQuiz', name: ENGINES.AUTOQUIZ },
    ],
  },
];

export default engineList;
