import React from 'react';
import { EventsTracker, HS } from '@apester/events';

import EVENTS from '../utils/events';

import PaymentPopup from './PaymentPopup';
import { isWixUser, redirectWixUserToPayments } from '../utils/helpers';

const { trackEvent: trackHSEvent } = HS;
class UpgradePlanButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shouldFadeIn: true,
    };
  }

  componentDidMount() {
    window.addEventListener('message', this.receiveOpenMessage, false);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.receiveOpenMessage, false);
  }

  receiveCloseMessage = (event) => {
    const { data, type } = event.data;
    if (type === 'close_payments') {
      const { onClose, redirectTo } = this.props;
      const redirectUrl = redirectTo || data?.redirectUrl;
      window.removeEventListener('message', this.receiveCloseMessage, false);
      this.setState({ shouldFadeIn: false });
      // time for animation to play
      setTimeout(() => {
        this.setState({ shouldShowPayment: false });
        if (onClose && !data.shouldRefresh) {
          onClose();
          return;
        }
        if (redirectUrl) {
          window.location = redirectUrl;
          return;
        }
        if (data.shouldRefresh) {
          window.location.reload();
        }
      }, 500);
    }
  };

  receiveOpenMessage = (event) => {
    const { user } = this.props;
    const { data, type } = event.data;

    if (type === 'open_payments') {
      this.setState({ planId: data.planId });
      this.onClickHandler(user, 'redirect');
    }
  };

  onClickHandler = async (user, origin) => {
    const { publisher, wixIntegrationUrl, upgradeRedirectUrl } = this.props;
    const { publisherId } = publisher;
    const wixUser = isWixUser(publisher);

    EventsTracker.SendFunnelEvent({
      name: EVENTS.PAYMENT_UPGRADE_CLICKED,
      type: 'element_clicked',
      action: 'click',
      element: 'button',
      category: 'manage',

      tab: origin,

      properties: {
        publisherId,
        wixUser,
      },
    });
    trackHSEvent('upgrade_clicked', origin);
    if (upgradeRedirectUrl) {
      // just open new tab if set upgrade redirect url
      window.open(upgradeRedirectUrl + getSidQueryString(true), '_blank');
      return;
    }
    if (wixUser) {
      await redirectWixUserToPayments(publisherId, wixIntegrationUrl);
      return;
    }

    window.addEventListener('message', this.receiveCloseMessage, false);

    this.setState({ shouldShowPayment: true, shouldFadeIn: true });
  };

  render() {
    const {
      user,
      origin,
      publisher,
      classNames,
      text,
      position,
      paymentsUrl,
    } = this.props;

    const { shouldShowPayment, shouldFadeIn, planId } = this.state;
    return (
      <>
        <button
          style={position && { top: position.top, left: position.left }}
          type="button"
          className={`upgrade-btn ${classNames || ''}`}
          onClick={() => {
            this.onClickHandler(user, origin);
          }}
        >
          {text || 'UPGRADE NOW'}
        </button>
        {shouldShowPayment
          && (
            <PaymentPopup
              shouldFadeIn={shouldFadeIn}
              publisher={publisher}
              planId={planId}
              paymentsUrl={paymentsUrl}
              user={user}
              origin={origin}
            />
          )}
      </>
    );
  }
}

export default UpgradePlanButton;
