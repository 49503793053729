import React from 'react';

const ChannelItem = (props) => {
  const {
    subscriptionClass, handleChannelClicked, channel, imagesUrl, index, selectedChannel,
  } = props;

  const isSelected = selectedChannel && channel.publisherId === selectedChannel.publisherId;
  return (
    <div
      className={`dropdown-button hasImage ${isSelected ? 'selected' : ''}`}
      onClick={() => {
        handleChannelClicked(channel);
      }}
      key={`inner-${index}`}
    >
      <div className={`buttonImage ${subscriptionClass.type ? `plans-${subscriptionClass.type}` : 'grey-border'}`}>
        <img
          alt="img"
          className="ic"
          src={`${imagesUrl}/insecure/fit/0/36/ce/0/plain/${encodeURIComponent(
            channel.profileImage.path,
          )}`}
        />
      </div>
      {subscriptionClass.type ? (
        <>
          <div className={`badge badge__${subscriptionClass.type}`}>
            <i className="ic icon-star" />
          </div>
          <div className="badge__innerText">{channel.name}</div>
          <div className={`badge__label__${subscriptionClass.type}`}>{subscriptionClass.name}</div>
        </>
      )
        : <div className="innerText">{channel.name}</div>}
    </div>
  );
};

export default ChannelItem;
