import React, { Component } from 'react';

import './ContactUsButton.scss';

const HUBSPOT_FORMS_URL = '//js.hsforms.net/forms/v2.js';
const HUBSPOT_PORTAL_ID = '5606725';
const HUBSPOT_FORM_ID = '37a10b11-fffb-42af-ac43-26182f30818c';
const HUBSPOT_SCRIPT_ID = 'ape-hubspot-script-form';

class ContactUsButton extends Component {
  componentDidMount() {
    if (window.document.getElementById(HUBSPOT_SCRIPT_ID)) {
      return;
    }

    const script = document.createElement('script');
    script.id = HUBSPOT_SCRIPT_ID;
    script.src = HUBSPOT_FORMS_URL;
    document.body.appendChild(script);
    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: HUBSPOT_PORTAL_ID,
          formId: HUBSPOT_FORM_ID,
          target: '#hubspotForm',
        });
      }
    });
  }

  render() {
    const { showContactUsForm } = this.props;
    return (
      <button
        type="button"
        className="contact-button"
        onClick={(() => {
          showContactUsForm(true);
        })}
      >
        contact us
      </button>
    );
  }
}

export default ContactUsButton;
